import React, { useContext, useState, useEffect } from 'react';
import StoreContext from '../context/StoreContext';
import ProductListingItem from '../components/ProductListing/ProductListingItem';
import styled from '@emotion/styled';
import { breakpoints, spacing } from '../utils/styles';
import { graphql } from 'gatsby';

// import Layout from '../components/layout';
const ProductListingContainer = styled(`div`)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: ${spacing.lg}px;

  @media (min-width: ${breakpoints.desktop}px) {
    flex-direction: row;
    flex-wrap: wrap;
    padding: ${spacing['2xl']}px;
  }
`;
const ProductSearchContainer = styled(`div`)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: ${spacing.lg}px;

  @media (min-width: ${breakpoints.desktop}px) {
    flex-direction: row;
    flex-wrap: wrap;
    padding: ${spacing['2xl']}px;
  }
`;

const Label = styled(`div`)`
  font-size: 0.6em;
  text-transform: uppercase;
`;
const SelectWrapper = styled(`div`)`
  font-size: 0.75em;
  text-transform: uppercase;
`;
const Select = styled(`select`)`
  font-size: 1.9em;
  background-image: none;
  background-color: transparent;
  border: 0 solid transparent;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
  height: 55px;
  opacity: 1;
  position: relative;
  padding-top: 10px;
  padding-left: 18px;
  padding-bottom: 10px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-position: right center;
  background-image: url(//cdn.shopify.com/s/files/1/0331/1311/2635/t/1/assets/ico-select.svg?v=3923620043948533822);
  background-repeat: no-repeat;
  background-position: right 10px center;
  line-height: 1.2;
  padding-right: 28px;
  text-indent: 0.01px;
  text-overflow: '';
  cursor: pointer;
  padding-top: 8px;
  padding-left: 15px;
  padding-bottom: 8px;
`;

const ProductListTemplate = ({ data }) => {
  const { edges: products } = data.allShopifyProduct;
  const context = useContext(StoreContext);
  const [type, setType] = useState(context.filteredType);
  const [sort, setSort] = useState(context.filteredSort);

  useEffect(() => {
    context.updateFilterType(type);
  }, [type]);

  useEffect(() => {
    context.updateFilterSort(sort);
  }, [sort]);

  const sorts = [];

  sorts.push(
    <>
      <option key={0} value="best-selling">
        Más vendidos
      </option>
      <option key={1} value="featured">
        Características
      </option>
      <option key={2} value="A-Z">
        Alfabeticamente, A-Z
      </option>
      <option key={3} value="Z-A">
        Alfabeticamente, Z-A
      </option>
      <option key={4} value="low">
        Precio, de bajo a alto
      </option>
      <option key={5} value="high">
        Precio, de alto a bajo
      </option>
    </>
  );
  const productTypes = [];
  const types = [];
  types.push(
    <option value="all" key="-1">
      All
    </option>
  );
  products.map((t, i) => {
    let type = t.node.productType;
    if (!productTypes.includes(type) && type.length > 0) {
      productTypes.push(type);
      types.push(
        <option key={i} value={type}>
          {type}
        </option>
      );
    }
    return null;
  });
  productTypes.sort();
  return (
    <>
      <ProductSearchContainer>
        <Label>ORDENAR POR :</Label>
        <SelectWrapper>
          <Select
            defaultvalues={sort}
            onChange={e => setSort(e.target.value)}
            id="sortBy"
          >
            {sorts}
          </Select>
        </SelectWrapper>

        <Label>FILTRAR POR :</Label>
        <SelectWrapper>
          <Select
            defaultvalues={type}
            onChange={e => setType(e.target.value)}
            id="filter"
          >
            {types}
          </Select>
        </SelectWrapper>
      </ProductSearchContainer>

      <ProductListingContainer>
        {context.filteredType === 'all'
          ? products
              .sort(
                context.filteredSort === 'low'
                  ? (a, b) =>
                      a.node.variants[0].price - b.node.variants[0].price
                  : context.filteredSort === 'high'
                  ? (a, b) =>
                      b.node.variants[0].price - a.node.variants[0].price
                  : context.filteredSort === 'Z-A'
                  ? (a, b) => b.node.title.localeCompare(a.node.title)
                  : (a, b) => a.node.title.localeCompare(b.node.title)
              )
              .map((p, i) => {
                let product = p.node;
                return (
                  <ProductListingItem key={product.id} product={product} />
                );
              })
          : products
              .filter(p => p.node.productType.includes(context.filteredType))
              .sort(
                context.filteredSort === 'featured'
                  ? a => a
                  : context.filteredSort === 'low'
                  ? (a, b) =>
                      a.node.variants[0].price - b.node.variants[0].price
                  : context.filteredSort === 'high'
                  ? (a, b) =>
                      b.node.variants[0].price - a.node.variants[0].price
                  : context.filteredSort === 'Z-A'
                  ? (a, b) => b.node.title.localeCompare(a.node.title)
                  : context.filteredSort === 'A-Z'
                  ? (a, b) => a.node.title.localeCompare(b.node.title)
                  : null
              )
              .map((p, i) => {
                let product = p.node;
                return (
                  <ProductListingItem key={product.id} product={product} />
                );
              })}
      </ProductListingContainer>
    </>
  );
};

export default ProductListTemplate;
export const ProductListQuery = graphql`
  query ProductListQuery($skip: Int!, $limit: Int!) {
    allShopifyProduct(
      sort: { fields: [publishedAt], order: ASC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          id
          handle
          title
          description
          descriptionHtml
          productType
          variants {
            shopifyId
            title
            price
            availableForSale
          }
          images {
            id
            localFile {
              childImageSharp {
                fluid(maxWidth: 910, maxHeight: 910) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`;
